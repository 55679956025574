import Actions from "modules/actions/client-accounts-actions";
import { uuid } from "vue-uuid";

export default {
  name: "FacilityContactInfo",
  props: {
    facilities: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      emailTypes: [],
      actions: new Actions(),
      dateMenu: false,
      dateMenu1: false,
      defaultIndex: "",
      dateValue: "",
      dateValue1: "",
      datePicker: [],
      panel: [0, 1, 2, 3],
      facilityCopy: {
        id: null,
        facilityId: "",
        sampleTestOrderCode: "",
        facilityType: "",
        label: "",
        npi: "",
        ein: "",
        facilityTaxonomyCode: null,
        terminationDate: "",
        effectivitydate: "",
        isReadonly: false,
        defaultContactInformation: {
          mainPhone: {
            phone: "",
          },
        },
      },
      isVerifying: {
        npi: false,
      },
      isLoading: false,
      rules: {
        required: (val) => {
          if (typeof val === "object")
            return (val.id && val.id !== null) || "This field is required";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateEmailType: (val, email) => {
          return email.length > 0
            ? (val !== null && val.length > 0) || "This field is required."
            : true;
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
      },
      progress: 0,
      progressLabel: "",
    };
  },
  computed: {
    __ContactInfo: {
      get() {
        return this.facilities.contactInfo;
      },
      set(value) {
        this.$emit("update:facilities", value);
      },
    },
    __Phones: {
      get() {
        return this.facilities.phones;
      },
      set(value) {
        this.$emit("update:facilities", value);
      },
    },
    isView() {
      return this.type == "view";
    },
  },
  methods: {
    async validatePhones() {
      let mainFaxes = await Promise.all(
        this.$refs.mainFax.map(async (item) => {
          return await item.$refs.form.validateAsync();
        })
      );
      let mainPhones = await Promise.all(
        this.$refs.mainPhone.map(async (item) => {
          return await item.$refs.form.validateAsync();
        })
      );
      let validateForms = [
        mainPhones.filter((bool) => !bool).length === 0,
        mainFaxes.filter((bool) => !bool).length === 0,
      ];
      return validateForms.filter((bool) => !bool).length === 0;
    },
    async getEmailTypes() {
      try {
        if (!this.emailTypes.length) {
          this.emailTypes = [
            { label: "Fetching email types...", disabled: true },
          ];

          const result = await this.actions.getEmailTypes();

          if (result) {
            this.emailTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Problem has occurred while saving data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      }
    },
    onRemoveContact(contactUUID) {
      const findIndex = this.__ContactInfo.findIndex(
        (item) => item.uuid === contactUUID
      );

      if (findIndex > -1) this.__ContactInfo.splice(findIndex, 1);
      this.$nextTick(() => {
        this.updateDefaultContact(this.defaultPhone);
      });
    },
    updateDefaultContact(e) {
      this.__ContactInfo.map((item) => {
        item.isDefault = false;
        return item;
      });

      const findIndex = this.__ContactInfo.findIndex((item) => item.uuid === e);

      const setIndex = findIndex > -1 ? findIndex : 0;

      this.__ContactInfo[setIndex].isDefault = true;
      this.defaultIndex = this.__ContactInfo[setIndex].uuid;
    },
    addFacilityContactInfo() {
      this.__ContactInfo.push({
        id: null,
        uuid: uuid.v1(),
        isDefault: false,
        firstName: "",
        middleName: "",
        lastName: "",
        nameTitle: "",
        email: {
          uuid: null,
          email: "",
          emailTypeUUID: null,
          emailType: "",
        },
        mainPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        mainFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
      });
    },
    async getContactInfoTypes() {
      try {
        if (!this.contactInfoTypes.length) {
          this.contactInfoTypes = [
            { label: "Fetching contactInfo types...", disabled: true },
          ];

          const result = await this.actions.getAllContactInfoTypes();

          if (result) {
            this.contactInfoTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Problem has occurred while saving data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      }
    },
  },

  mounted() {
    if (this.defaultIndex == "") {
      this.defaultIndex = this.__ContactInfo[0].uuid;
    }
  },
};
