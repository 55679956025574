import SampleStorageLaboratory from "store/models/SampleStorageLaboratory";
import Actions from "modules/actions/sample-storage-laboratory-actions";
import AddFacilityModal from "./modals/AddFacilityModal";
import { uuid } from "vue-uuid"

const actions = new Actions();

export default {
  name: "SampleStorageLaboratories",
  components: { AddFacilityModal },
  data() {
    return {
      addDialog: false,
      dialog: false,
      type: "add",
      selectedPayorId: null,
      initialDataLoading: false,
      isDataLoading: false,
      facilities: [],
      filter: "",
      orderBy: {
        facilityId: "orderByFacilityId",
        facilityName: "orderByLabel",
        facilityType: "orderByFacilityType",
        taxonomyCode: "orderByTaxonomyCode",
        taxId: "orderByTaxId",
        npi: "orderByNpi",
        effectivityDate: "orderByEffectivityDate",
        terminationDate: "orderByTerminationDate",
        sampleTestOrderCode: "orderBySampleCount",
        status: "orderByValidityStatus",
      },
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      columns: [
        {
          name: "facilityId",
          align: "left",
          text: "Facility ID",
          field: "facilityId",
          sortable: true,
        },
        {
          name: "label",
          align: "left",
          text: "Facility Name",
          field: "facilityName",
          sortable: true,
        },
        {
          name: "facilityType",
          align: "left",
          text: "Facility Type",
          field: "facilityType",
          sortable: true,
        },
        {
          name: "npi",
          align: "left",
          text: "Facility NPI",
          field: "npi",
          sortable: true,
        },
        {
          name: "taxonomyCode",
          align: "left",
          text: "Facility Taxonomy Code",
          field: "taxonomyCode",
          sortable: true,
        },
        {
          name: "taxId",
          align: "left",
          text: "Facility Tax ID/EIN",
          field: "taxId",
          sortable: true,
        },
        {
          name: "effectivityDate",
          align: "left",
          text: "Effective Date",
          field: "effectivityDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "terminationDate",
          align: "left",
          text: "Termination Date",
          field: "terminationDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          isStatusColumn: true,
          sortable: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 4,
        model: SampleStorageLaboratory,
        fields: [
          {
            label: "Facility ID",
            name: "facilityId",
            type: "text",
          },
          {
            label: "Facility Name",
            name: "label",
            type: "text",
          },
          {
            label: "Facility Type",
            name: "facilityType",
            type: "select",
            selectOptions: [
              {
                label: "Main Lab",
                value: "Main_Lab",
              },
              {
                label: "Subsidiary of MainLab",
                value: "Subsidiary_of_Main_Lab",
              },
              {
                label: "Wholly Owned Subsidiary of Main Lab",
                value: "Wholly_Owned_Subsidiary_of_Main_Lab",
              },
            ],
            isEnum: true,
            singleSelect: true,
          },
          {
            label: "Facility NPI",
            name: "npi",
            type: "text",
            fields: ["npiNumber"],
            isMultiFields: true,
          },
          {
            label: "Facility Taxonomy Code",
            name: "taxonomyCode",
            type: "text",
          },
          {
            label: "Facility Tax ID/EIN",
            name: "taxId",
            type: "text",
          },
          {
            label: "Effective Date",
            name: "effectivityDate",
            type: "date",
            format: "MM/DD/YYYY",
          },
          {
            label: "Termination Date",
            name: "terminationDate",
            type: "date",
            format: "MM/DD/YYYY",
          },
        ],
      },
      limitData: [
        "label",
        "facilityType",
        "taxId",
        "facilityId",
        "taxonomyCode",
        "effectivityDate",
        "terminationDate",
        this.buildSubQuery("npi", ["npiNumber"]),
        "archived",
        "totalCount",
      ],
      totalCount: 0,
      facilityModel: {
        id: null,
        uuid: null,
        facilityId: "",
        facilityType: "",
        label: "",
        npi: {
          id: "",
          npiNumber: "",
        },
        taxId: null,
        taxonomyCode: null,
        terminationDate: null,
        contactPersons: [
          {
            id: null,
            firstName: "",
            lastName: "",
            middleName: "",
            nameTitle: "",
            phones: [
              {
                id: null,
                phone: "",
                areaCode: "",
              },
              {
                id: null,
                phone: "",
                areaCode: "",
              },
            ],
            emails: [
              {
                id: null,
                email: "",
                emailType: {
                  id: null,
                  label: "",
                },
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    filteredFacilities() {
      try {
        let data = this.facilities.map((item) => {
          return {
            id: item.id,
            facilityName: item.label,
            facilityType: item.facilityType.split("_").join(" "),
            taxId: item.taxId,
            facilityId: item.facilityId,
            taxonomyCode: item.taxonomyCode,
            effectivityDate: item.effectivityDate
              ? this.$options.filters.getTimeDate(
                  item.effectivityDate,
                  "MM/DD/YYYY"
                ).zone
              : null,
            terminationDate: item.terminationDate
              ? this.$options.filters.getTimeDate(
                  item.terminationDate,
                  "MM/DD/YYYY"
                ).zone
              : null,
            npi: item.npi ? item.npi.npiNumber : "-",
            status: !item.archived ? "Active" : "Deactivated",
            isActive: !item.archived,
          };
        });
        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
  },
  methods: {
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async closeModal(preventStatus = true) {
      if (!preventStatus) {
        this.dialog = true;
      } else {
        this.addDialog = false;
        await this.fetch();
      }
    },
    confirmAction() {
      this.dialog = false;
      this.addDialog = false;
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.facilities = result;
    },

    async addFacility() {
      await actions.getAllAddressTypes({
        filter: {
          forBilling: true,
        },
      });
      this.facilityModel = {
        id: null,
        uuid: null,
        sampleTestOrderCode: "",
        effectivityDate: null,
        facilityId: null,
        facilityType: "",
        label: "",
        npi: {
          id: "",
          npiNumber: "",
        },
        taxId: null,
        taxonomyCode: null,
        terminationDate: null,
        isReadonly: false,
        contactInfo: [
          {
            isDefault: true,
            id: null,
            uuid: uuid.v1(),
            firstName: "",
            middleName: "",
            lastName: "",
            nameTitle: "",
            email: {
              uuid: null,
              email: "",
              emailTypeUUID: null,
              emailType: "",
            },
            mainPhone: {
              uuid: null,
              phone: "",
              areaCode: "",
            },
            mainFax: {
              uuid: null,
              phone: "",
              areaCode: "",
            },
          },
        ],
        addresses: [
          {
            address: {
              isDefault: true,
              uuid: uuid.v1(),
              line1: "",
              line2: "",
              city: "",
              postalCode: "",
              country: "",
              stateProvince: "",
              addressTypeUUID: null,
              addressType: "",
              isReadonly: false,
            },
            mainPhone: {
              uuid: null,
              phone: "",
              areaCode: "",
            },
            mainFax: {
              uuid: null,
              phone: "",
              areaCode: "",
            },
          },
        ],
        defaultContactInformation: {
          mainPhone: {
            phone: "",
          },
        },
      };
      this.type = "Add";
      this.addDialog = true;
    },
    editFacility(id) {
      this.$router.push(`/system-maintenance/facilities/edit/${id}`);
    },
    viewFacility(id) {
      this.type = "view";
      const facility = this.facilities.find((x) => x.id == id);
      this.facilityModel = {
        ...facility,
        uuid: facility.id,
        addresses: facility.addresses || [],
        defaultContactInformation: {
          mainPhone: {
            phone: "",
          },
        },
      };

      this.addDialog = true;
    },
    toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },
    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },
    async getChangeStatusAction(params) {
      const { isActive } = this.changeStatusForm;
      if (isActive) {
        return await actions.deactivateFacility(params);
      }
      return await actions.activateFacility(params);
    },
    async onSubmitChangeStatus() {
      const { isActive, isDisabled, id } = this.changeStatusForm;
      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await this.getChangeStatusAction(params);
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Facility status.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: `Facility ${
              isActive ? "deactivated" : "activated"
            }.`,
            type: "success",
          });
          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };
          this.fetch();
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    authUserHasAccessControl(task) {
      return this.$store.getters.authUserHasAccessControl(task);
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
