import Actions from "modules/actions/client-accounts-actions";
import { uuid } from "vue-uuid"

export default {
  name: "FacilityAddress",
  props: {
    facilities: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      actions: new Actions(),
      dateMenu: false,
      dateMenu1: false,
      defaultIndex: "",
      dateValue: "",
      dateValue1: "",
      datePicker: [],
      panel: [0, 1, 2, 3],
      facilityCopy: {
        id: null,
        facilityId: "",
        sampleTestOrderCode: "",
        facilityType: "",
        label: "",
        npi: "",
        ein: "",
        facilityTaxonomyCode: null,
        terminationDate: "",
        effectivitydate: "",
        isReadonly: false,
        defaultContactInformation: {
          mainPhone: {
            phone: "",
          },
        },
      },
      isVerifying: {
        npi: false,
      },
      isLoading: false,
      rules: {
        validateFacilityId: async (val) => {
          const result = await this.doFacilityId(val);
          return result;
        },
        validateNpiNumber: async (val) => {
          const result = await this.doValidateNpiNumber(val);
          return result;
        },

        required: [
          (val) => {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === ""
            ) {
              return "This field is required";
            }
            return true;
          },
        ],
      },
      progress: 0,
      progressLabel: "",
    };
  },
  computed: {
    __Addresses: {
      get() {
        return this.facilities.addresses;
      },
      set(value) {
        this.$emit("update:facilities", value);
      },
    },
    __Phones: {
      get() {
        return this.facilities.phones;
      },
      set(value) {
        this.$emit("update:facilities", value);
      },
    },
  },
  methods: {
    async validateForm() {
      let mainFaxes = await Promise.all(
        this.$refs.mainFax.map(async (item) => {
          return await item.$refs.form.validateAsync();
        })
      );
      let mainPhones = await Promise.all(
        this.$refs.mainPhone.map(async (item) => {
          return await item.$refs.form.validateAsync();
        })
      );
      let facilityAddress = await Promise.all(
        this.$refs.facilityAddress.map(async (item) => {
          return await item.$refs.form.validateAsync();
        })
      );
      let validateForms = [
        facilityAddress.filter((bool) => !bool).length === 0,
        mainPhones.filter((bool) => !bool).length === 0,
        mainFaxes.filter((bool) => !bool).length === 0,
      ];
      return validateForms.filter((bool) => !bool).length === 0;
    },
    onRemoveAddress(addressUUID) {
      const findIndex = this.__Addresses.findIndex(
        (item) => item.address.uuid === addressUUID
      );


      if (findIndex > -1) this.__Addresses.splice(findIndex, 1);
      
      this.$nextTick(() => {
        this.updateDefaultAddress(this.defaultPhone);
      });
    },

    updateDefaultAddress(e){
      this.__Addresses.map((item) => {
        item.address.isDefault = false;
        return item;
      });

      const findIndex = this.__Addresses.findIndex((item) =>  item.address.uuid === e);

      const setIndex = findIndex > -1 ? findIndex: 0;

      this.__Addresses[setIndex].isDefault = true;
      this.defaultIndex = this.__Addresses[setIndex].address.uuid;
    },
    addFacilityAddress() {
      this.__Addresses.push({
        address: {
          isDefault: false,
          uuid: uuid.v1(),
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        mainPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        mainFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
      });
    },

    async getAddressTypes() {
      try {
        if (!this.addressTypes.length) {
          this.addressTypes = [
            { label: "Fetching address types...", disabled: true },
          ];

          const result = await this.actions.getAllAddressTypes({
            filter: {
              forBilling: true,
            },
          });

          if (result) {
            this.addressTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Problem has occurred while saving data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      }
    },
  },
  mounted() {
    if(this.defaultIndex == "") {
      this.defaultIndex = this.__Addresses[0].address.uuid;
    }
  }
};
