var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.__Addresses)?_c('v-form',{directives:[{name:"async-form",rawName:"v-async-form"}],ref:"form"},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.__Addresses),function(item){return _c('v-col',{key:item.address.uuid,staticClass:"pa-2",attrs:{"cols":12}},[_c('v-card',{staticClass:"pa-2 ma-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-radio-group',{model:{value:(_vm.defaultIndex),callback:function ($$v) {_vm.defaultIndex=$$v},expression:"defaultIndex"}},[_c('v-radio',{attrs:{"label":"Default","value":item.address.uuid,"name":"setDefault"},on:{"change":function($event){return _vm.updateDefaultAddress(item.address.uuid)}}})],1)],1),_c('v-col',[(_vm.type != 'view' && _vm.__Addresses.length > 1)?_c('v-icon',{staticClass:"float-right",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.onRemoveAddress(item.address.uuid)}}},[_vm._v("mdi-delete")]):_vm._e()],1)],1),_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-address-section',{ref:"facilityAddress",refInFor:true,attrs:{"address":item.address,"customFields":[
                  {
                    type: 'type',
                    label: 'Address Type',
                    rules: [].concat( _vm.rules.required ),
                  },
                  {
                    type: 'line1',
                    label: 'Address Line 1',
                    rules: [].concat( _vm.rules.required ),
                  },
                  {
                    type: 'line2',
                    label: 'Address Line 2',
                  },
                  {
                    type: 'country',
                    label: 'Country',
                    rules: [].concat( _vm.rules.required ),
                  },
                  {
                    type: 'city',
                    label: 'City',
                    rules: [].concat( _vm.rules.required ),
                  },
                  {
                    type: 'state',
                    label: 'State/Province',
                    rules: [].concat( _vm.rules.required ),
                  },
                  {
                    type: 'zipCode',
                    label: 'Postal Code',
                    rules: [].concat( _vm.rules.required ),
                  } ],"disabled":_vm.type === 'view'},on:{"update:address":function($event){return _vm.$set(item, "address", $event)}}})],1)],1),_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"one-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 pa-1"},[_vm._v(" Main Phone No"),_c('sup',[_vm._v("*")])])],1)],1),_c('v-card-text',[(_vm.type == 'view' && _vm.facilityCopy.mainPhone.length == 0)?_c('span',[_vm._v(" No Main Phone Details")]):_vm._e(),_c('v-phone-section-2',{ref:"mainPhone",refInFor:true,attrs:{"label":"","phones":[item.mainPhone],"singleSelection":true}})],1)],1),_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"one-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 pa-1"},[_vm._v(" Main Fax No"),_c('sup',[_vm._v("*")])])],1)],1),_c('v-card-text',[_c('v-phone-section-2',{ref:"mainFax",refInFor:true,attrs:{"label":"","phones":[item.mainFax],"singleSelection":true}})],1)],1)],1)],1)],1)}),_c('v-btn',{attrs:{"elevation":"2","disabled":_vm.type == 'view'},on:{"click":_vm.addFacilityAddress}},[_vm._v("+Add New Facility Address")])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }