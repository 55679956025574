/* eslint-disable no-debugger */
import actions from "modules/actions/sample-storage-laboratory-actions";
import GeneralInformation from "../../shared/GeneralInformation";
import FacilityAddress from "./sections/FacilityAddress";
import ContactInformation from "./sections/ContactInformation";
import isEqual from "lodash/isEqual";

export default {
  name: "AddFacility",
  components: {
    actions,
    GeneralInformation,
    FacilityAddress,
    ContactInformation,
  },
  props: {
    facilities: Object,
    type: String,
  },
  data() {
    return {
      panel: [0, 1, 2, 3],
      facilityCopy: null,
      isVerifying: {
        npi: false,
      },
      isLoading: false,
      actions: new actions(),
      rules: {
        validateNpiNumber: async (val, type) => {
          const result = await this.doValidateNpiNumber(val, type);
          return result;
        },

        required: [
          (val) => {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === ""
            ) {
              return "This field is required";
            }
            return true;
          },
        ],
      },
      progress: 0,
      progressLabel: "",
      createNpiNumber: null,
      createdClaimAddress: null,
      defaultCreatedContactInfoId: null,
      defaultCreatedAddressId: null,
    };
  },
  computed: {
    phoneCountryCodeOptions() {
      return this.getAllPhoneCountryCodes().map((country) => {
        return {
          shortName: country.id,
          label: `${country.name} (+${country.phone})`,
          value: `${country.name}-${country.phone}`,
          code: country.phone,
        };
      });
    },
  },
  methods: {
    async processContactInfo(collectionQueries) {
      try {
        const { contactInfo } = this.facilityCopy;
        for (let contactInformation of contactInfo) {
          const { mainPhone, mainFax, email } = contactInformation;

          if (contactInformation.id) {
            const ciParam = {
              variables: {
                id: {
                  type: "UUID",
                  value: contactInformation.id,
                },
              },
              queries: {
                updateName: {
                  firstName: {
                    type: "String",
                    value: contactInformation.firstName,
                  },
                  middleName: {
                    type: "String",
                    value: contactInformation.middleName,
                  },
                  lastName: {
                    type: "String",
                    value: contactInformation.lastName,
                  },
                },
                setNameTitle: {
                  nameTitle: {
                    type: "NameTitle!",
                    value: contactInformation.nameTitle,
                  },
                },
                updatePhone: {
                  phoneId: {
                    type: "UUID!",
                    value: mainPhone.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainPhone.areaCode),
                  },
                },
                updateFax: {
                  phoneId: {
                    type: "UUID!",
                    value: mainFax.id,
                  },
                  phone: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.phone),
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(mainFax.areaCode),
                  },
                },
                updateEmail: {
                  emailId: {
                    type: "UUID!",
                    value: email.id,
                  },
                  email: {
                    type: "String",
                    value: email.email,
                  },
                  emailTypeId: email.emailTypeId,
                },
              },
            };

            await this.actions.updateContactInformation(ciParam);
          } else {
            const createMainPhone = await this.createPhone(mainPhone);
            const createMainFax = await this.createPhone(mainFax);
            const createEmail = await this.handleCreateEmail(email.email);
            let ciParam = {
              variables: {
                firstName: {
                  type: "String!",
                  value: contactInformation.firstName,
                },
                lastName: {
                  type: "String!",
                  value: contactInformation.lastName,
                },
                nameTitle: {
                  type: "String!",
                  value: contactInformation.nameTitle,
                },
              },
              queries: {
                setMainPhone: {
                  phoneId: {
                    type: "UUID!",
                    value: createMainPhone.id,
                  },
                },
                setEmail: {
                  emailId: {
                    type: "UUID!",
                    value: createEmail.id,
                  },
                },
              },
              collectionQueries: [
                {
                  setMainFax: {
                    phoneId: {
                      type: "UUID!",
                      value: createMainFax.id,
                    },
                  },
                },
              ],
            };

            if (contactInformation.middleName.length)
              ciParam.variables["middleName"] = {
                type: "String",
                value: contactInformation.middleName,
              };

            const createContactInformation =
              await this.actions.createContactInformation(ciParam);

            mainPhone.id = createMainPhone.id;
            mainFax.id = createMainFax.id;
            if (createEmail) email.id = createEmail.id;
            contactInformation.id = createContactInformation.id;

            collectionQueries.push({
              addContactInformation: {
                contactInformationId: {
                  type: "UUID!",
                  value: contactInformation.id,
                },
              },
            });
            if (contactInformation.isDefault) {
              this.defaultCreatedContactInfoId = contactInformation.id;
            }
          }
        }

        return collectionQueries;
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
        return collectionQueries;
      }
    },
    async handleCreateEmail(email) {
      try {
        const result = await this.actions.createEmail({
          variables: {
            email: {
              type: "String!",
              value: email,
            },
          },
          queries: {},
        });

        return result;
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
    },
    async processNpi() {
      if (this.facilityCopy.npi && !this.facilityCopy.npi.id) {
        let param = {};
        param = {
          npiNumber: {
            type: "String!",
            value: this.facilityCopy.npi.npiNumber,
            unincludeToFields: true,
          },
        };
        this.createNpiNumber = await this.actions.createNpi({
          params: param,
        });
      }
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return month && day && year
        ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        : null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    async resetValidation() {},
    async validateSectionForms() {
      try {
        let validateForms = [
          await this.$refs.generalInformation.$refs.form.validateAsync(),
          await this.$refs.contactInformation.$refs.form.validateAsync(),
          await this.$refs.addressInformation.validateForm(),
          await this.$refs.contactInformation.validatePhones(),
        ];
        return validateForms.filter((bool) => !bool).length === 0;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async createAddress(paramAddress) {
      const { address, mainFax, mainPhone } = paramAddress;
      let params = {
        line1: {
          type: "String!",
          value: address.line1,
        },
        line2: {
          type: "String",
          value: address.line2,
        },
        city: {
          type: "String",
          value: address.city,
        },
        postalCode: {
          type: "String",
          value: address.postalCode,
        },
        country: {
          type: "String",
          value: address.country,
        },
        stateProvince: {
          type: "String",
          value: address.stateProvince,
        },
      };

      const createdPhone = await this.createPhone(mainPhone);
      const createdFax = await this.createPhone(mainFax);

      return await this.actions.createAddress({
        params,
        queries: {
          setAddressType: {
            addressTypeId: {
              type: "UUID!",
              value: address.addressType,
            },
          },
        },
        collectionQueries: [
          {
            setMainPhone: {
              phoneId: {
                type: "UUID!",
                value: createdPhone.id,
              },
            },
            setMainFax: {
              phoneId: {
                type: "UUID!",
                value: createdFax.id,
              },
            },
          },
        ],
      });
    },
    async createPhone(phone) {
      let param = {
        phone: {
          type: "String!",
          value: this.sanitizePhoneString(phone.phone),
          unincludeToFields: true,
        },
        areaCode: {
          type: "String!",
          value: this.sanitizePhoneString(phone.areaCode),
          unincludeToFields: true,
        },
        collectionQueries: [],
        queries: {},
      };
      return await this.actions.createPhone(param);
    },

    async processPhones(collectionQueries) {
      for (var i = 0; i < this.facilityCopy.addresses.length; i++) {
        if (
          this.facilityCopy.addresses[i].mainFax &&
          !this.facilityCopy.addresses[i].mainFax.uuid
        ) {
          const result = await this.createPhone(
            this.facilityCopy.addresses[i].mainFax
          );
          this.facilityCopy.addresses[i].mainFax.uuid = result.id;
          collectionQueries.push({
            updateAddress: {
              addressId: {
                type: "UUID!",
                value: this.facilityCopy.addresses[i].address.uuid,
              },
              mainFax: {
                type: "UUID!",
                value: result.id,
              },
            },
          });
        } else {
          collectionQueries.push({
            updatePhone: {
              phoneId: {
                type: "UUID!",
                value: this.facilityCopy.addresses[i].mainFax.uuid,
              },
              phone: {
                type: "String",
                value: this.sanitizePhoneString(
                  this.facilityCopy.addresses[i].mainFax.phone
                ),
              },
              areaCode: {
                type: "String",
                value: this.sanitizePhoneString(
                  this.facilityCopy.addresses[i].mainFax.areaCode
                ),
              },
            },
          });
        }

        if (
          this.facilityCopy.addresses[i].mainPhone &&
          !this.facilityCopy.addresses[i].mainPhone.uuid
        ) {
          const result = await this.createPhone(
            this.facilityCopy.addresses[i].mainPhone
          );
          this.facilityCopy.addresses[i].mainPhone.uuid = result.id;
          if (
            this.facilityCopy.addresses[i].address &&
            this.facilityCopy.addresses[i].address.uuid
          ) {
            collectionQueries.push({
              updateAddress: {
                addressId: {
                  type: "UUID!",
                  value: this.facilityCopy.addresses[i].address.uuid,
                },
                mainPhone: {
                  type: "UUID!",
                  value: result.id,
                },
              },
            });
          }
        } else {
          collectionQueries.push({
            updatePhone: {
              phoneId: {
                type: "UUID!",
                value: this.facilityCopy.addresses[i].mainPhone.uuid,
              },
              phone: {
                type: "String",
                value: this.sanitizePhoneString(
                  this.facilityCopy.addresses[i].mainPhone.phone
                ),
              },
              areaCode: {
                type: "String",
                value: this.sanitizePhoneString(
                  this.facilityCopy.addresses[i].mainPhone.phone
                ),
              },
            },
          });
        }
      }
      return collectionQueries;
    },
    async processAddresses(collectionQueries) {
      for (var i = 0; i < this.facilityCopy.addresses.length; i++) {
        if (
          this.facilityCopy.addresses[i].address &&
          !this.facilityCopy.addresses[i].address.uuid
        ) {
          const result = await this.createAddress(
            this.facilityCopy.addresses[i]
          );
          this.facilityCopy.addresses[i].address.uuid = result.id;
          if (this.facilityCopy.addresses[i].address.isDefault)
            this.defaultCreatedAddressId = result.id;
          collectionQueries.push({
            addAddress: {
              addressId: {
                type: "UUID!",
                value: result.id,
              },
            },
          });
        } else {
          collectionQueries.push({
            updateAddress: {
              addressTypeId: {
                type: "UUID!",
                value: this.facilityCopy.addresses[i].address.addressType,
              },
              addressId: {
                type: "UUID!",
                value: this.facilityCopy.addresses[i].address.uuid,
              },
              line1: {
                type: "String",
                value: this.facilityCopy.addresses[i].address.line1,
              },
              city: {
                type: "String",
                value: this.facilityCopy.addresses[i].address.city,
              },
              postalCode: {
                type: "String",
                value: this.facilityCopy.addresses[i].address.postalCode,
              },
              country: {
                type: "String",
                value: this.facilityCopy.addresses[i].address.country,
              },
              stateProvince: {
                type: "String",
                value: this.facilityCopy.addresses[i].address.stateProvince,
              },
            },
          });
        }
      }
      return collectionQueries;
    },
    async saveFacility() {
      try {
        this.isLoading = true;
        const facilitySaveCopy = this.facilityCopy;
        this.progressLabel = "Validating Fields...";
        const allSectionsAreValidated = await this.validateSectionForms();

        if (!allSectionsAreValidated) {
          this.isLoading = false;
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          return;
        }
        let params = {};
        let collectionQueries = [];
        let queries = [];
        this.progress = 25;
        this.progressLabel = "Processing NPI...";
        await this.processNpi();
        this.progress = 50;
        this.progressLabel = "Processing Address...";
        collectionQueries = await this.processAddresses(collectionQueries);
        this.progress = 80;
        this.progressLabel = "Processing Contacts...";
        collectionQueries = await this.processContactInfo(collectionQueries);

        collectionQueries.push({
          updateValidityDates: {
            effectivityDate: {
              type: "String",
              value: facilitySaveCopy.effectivityDate
                ? this.$options.filters.getTimeDate(
                    facilitySaveCopy.effectivityDate,
                    "YYYY-MM-DD HH:mm:ss",
                    false,
                    null,
                    this.getTimezoneOffset({
                      date: facilitySaveCopy.effectivityDate,
                    })
                  ).utc
                : "",
                unincludeToFields: true,
            },
            terminationDate: {
              type: "String",
              value: facilitySaveCopy.terminationDate
                ? this.$options.filters.getTimeDate(
                    facilitySaveCopy.terminationDate,
                    "YYYY-MM-DD HH:mm:ss",
                    false,
                    null,
                    {
                      days: 1,
                      ...this.getTimezoneOffset({
                        date: facilitySaveCopy.terminationDate,
                        minusSeconds: 1,
                      }),
                    }
                  ).utc
                : "",
              unincludeToFields: true,
            },
            mutationReturn: ["success", "errors"],
          },
        });
        queries["updateFacilityId"] = {
          facilityId: {
            type: "String",
            value: facilitySaveCopy.facilityId,
          },
        };
        queries["updateTaxId"] = {
          taxId: {
            type: "String",
            value: facilitySaveCopy.taxId,
          },
        };
        queries["setNpi"] = {
          npiId: {
            type: "UUID!",
            value: this.createNpiNumber.id,
          },
        };
        queries["updateFacilityType"] = {
          facilityType: {
            type: "FacilityType!",
            value: facilitySaveCopy.facilityType,
          },
        };
        queries["updateTaxonomyCode"] = {
          taxonomyCode: {
            type: "String",
            value: facilitySaveCopy.taxonomyCode,
          },
        };

        if (this.defaultCreatedContactInfoId) {
          queries["setDefaultContactInformation"] = {
            contactInformationId: {
              type: "UUID!",
              value: this.defaultCreatedContactInfoId,
            },
          };
        }

        if (this.defaultCreatedAddressId) {
          queries["setDefaultAddress"] = {
            addressId: {
              type: "UUID!",
              value: this.defaultCreatedAddressId,
            },
          };
        }

        if (facilitySaveCopy.id) {
          await this.actions.updateSampleStorageLaboratory({
            storageLabId: {
              type: "UUID!",
              value: facilitySaveCopy.id,
              unincludeToFields: true,
            },
            queries,
            collectionQueries,
          });
        } else {
          {
            params = {
              label: {
                type: "String!",
                value: facilitySaveCopy.label,
                unincludeToFields: true,
              },
              sampleTestOrderCode: {
                type: "String",
                value: facilitySaveCopy.sampleTestOrderCode,
                unincludeToFields: true,
              },
            };
          }
          const result = await this.actions.createSampleStorageLaboratory({
            params: params,
          });
          await this.actions.updateSampleStorageLaboratory({
            storageLabId: {
              type: "UUID!",
              value: result.id,
              unincludeToFields: true,
            },
            queries,
            collectionQueries,
          });
        }
        this.showNotifyMessage({
          message: "Successfully saved ",
          type: "success",
        });
        this.$emit("closeModal");
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
      this.isLoading = false;
    },

    onCancel() {
      if (!isEqual(this.facilities, this.facilityCopy)) {
        this.$emit("closeModal", false);
      } else {
        this.$emit("closeModal", true);
      }
    },
  },
  async created() {
    this.$nextTick(async () => {
      this.facilityCopy = JSON.parse(JSON.stringify(this.facilities));
    });
  },
};
